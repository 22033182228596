import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {SiTelegram} from 'react-icons/si';

const Footer = () => {
    const location = useLocation();

    return (
        <footer className='text-center bg-gray-200 py-8 w-full'>
            <div className='mb-12 flex flex-row items-center justify-center gap-40'>
                <p className='text-lg font-light'>Try the best AI assistant and hack your meetings!</p>
                <a href='https://chromewebstore.google.com/detail/patsa/pnhmmhieodnagejlokknhmhbmfpgohnn'
                   target='_blank' rel='noopener noreferrer'
                   className='h-16 bg-green-600 hover:bg-green-700 text-lg text-white font-bold py-2 px-4 rounded flex items-center justify-center'>
                    Go to Chrome Web Store
                </a>
            </div>
            <div className='mb-8'>
                <a href='https://t.me/vstrk' target='_blank' rel='noopener noreferrer' aria-label='telegram'>
                    <SiTelegram className='text-blue-500 w-6 h-6 text-4xl inline'/>
                </a>
            </div>
            <div>
                {location.pathname !== '/policy' && (
                    <Link to='/policy' className='text-green-600 mr-4'>
                        Privacy Policy
                    </Link>
                )}
                {location.pathname !== '/terms' && (
                    <Link to='/terms' className='text-green-600'>
                        Terms and Conditions
                    </Link>
                )}
            </div>
        </footer>
    );
};

export default Footer;