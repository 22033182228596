import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import config from '../config';
import {useGoogleLogin} from '@react-oauth/google';
import {FcGoogle} from "react-icons/fc";

const GoogleSignIn = ({onClose}) => {
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleGoogleSignIn = useGoogleLogin({
        onSuccess: async tokenResponse => {
            try {
                const response = await fetch(`${config.httpUrl}/googleSignIn`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: `token=${tokenResponse.access_token}`,
                });

                const responseBody = await response.text();

                if (response.ok) {
                    localStorage.setItem('jwt', responseBody);
                    navigate('/user');
                    onClose();
                } else {
                    setErrorMessage(responseBody ? responseBody : 'Sign in with Google failed');
                }
            } catch (error) {
                console.error('An error occurred during login:', error);
                setErrorMessage('An error occurred during login.');
            }
        }, onError: (error) => {
            console.error('An error occurred during login:', error);
            setErrorMessage('An error occurred during login.');
        }
    });

    return (
        <div className='mt-6'>
            <div className='relative'>
                <div className='absolute inset-0 flex items-center'>
                    <div className='w-full border-t border-gray-300'></div>
                </div>
                <div className='relative flex justify-center text-sm'>
                            <span className='px-2 bg-gray-100 text-gray-500'>
                                Or sign in with
                            </span>
                </div>
            </div>
            {errorMessage && <p className='mt-2 text-center text-sm text-red-600 '>{errorMessage}</p>}
            <div className='mt-6'>
                <button onClick={handleGoogleSignIn}
                        className='w-full py-2 flex justify-center border border-green-200 rounded-lg hover:border-green-500 hover:shadow transition duration-150'>
                    <FcGoogle className='w-6 h-6'/>
                </button>
            </div>
        </div>
    );
}


export default GoogleSignIn;