import React, {useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import Login from './dialog/Login';
import SignUp from './dialog/SignUp';
import ForgotPassword from "./dialog/ForgotPassword";

const Header = () => {
    const jwt = localStorage.getItem('jwt');
    const [loginDialogVisible, setLoginDialogVisible] = useState(false);
    const [signUpDialogVisible, setSignUpDialogVisible] = useState(false);
    const [forgotPasswordVisible, setForgotPasswordDialogVisible] = useState(false);
    const navigate = useNavigate();

    const openLoginDialog = () => {
        setLoginDialogVisible(true);
    };

    const closeLoginDialog = () => {
        setLoginDialogVisible(false);
    };

    const openSignUpDialog = () => {
        setSignUpDialogVisible(true);
    };

    const closeSignUpDialog = () => {
        setSignUpDialogVisible(false);
    };

    const openForgotPasswordDialog = () => {
        setForgotPasswordDialogVisible(true);
    };

    const closeForgotPasswordDialog = () => {
        setForgotPasswordDialogVisible(false);
    };

    const logout = () => {
        localStorage.removeItem('jwt');
        navigate('/landing');
    };

    return (
        <nav className='flex items-center justify-between p-4'>
            <Link to={localStorage.getItem('jwt') ? '/user' : '/landing'} className='flex items-center'>
                <img src='/logo.png' alt='Patsa AI-powered Meeting Copilot' className='w-24 h-24 mr-2'/>
                <h1 className='text-3xl font-medium text-green-600'>Patsa - AI-powered Meeting Copilot</h1>
            </Link>
            <div>
                {jwt ? (
                    <button onClick={logout}
                            className='py-2 px-4 mr-2 border border-transparent text-sm font-medium rounded-lg text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'>
                        Logout
                    </button>
                ) : (
                    <React.Fragment>
                        <div className='mb-3'>
                            <button
                                onClick={openLoginDialog}
                                className='py-2 px-4 mr-2 border border-transparent text-sm font-medium rounded-lg text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'>
                                Login
                            </button>
                            <button
                                onClick={openSignUpDialog}
                                className='py-2 px-4 border border-transparent text-sm font-medium rounded-lg text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'>
                                Signup
                            </button>
                        </div>
                        <button onClick={openForgotPasswordDialog}  className='font-medium text-sm text-blue-600 hover:text-blue-500 focus:outline-none'>
                            Forgot password?
                        </button>
                        {loginDialogVisible && <Login onClose={closeLoginDialog}/>}
                        {signUpDialogVisible && <SignUp onClose={closeSignUpDialog}/>}
                        {forgotPasswordVisible && <ForgotPassword onClose={closeForgotPasswordDialog}/>}
                    </React.Fragment>
                )}
            </div>
        </nav>
    );
}

export default Header;
