import React, {useRef} from 'react';
import {GoPeople, GoStar} from 'react-icons/go';
import {IoLanguage} from "react-icons/io5";

const Landing = () => {
    const nextPageRef = useRef(null);

    const handleClick = () => {
        window.scrollTo({top: nextPageRef.current.offsetTop, behavior: 'smooth'});
    };

    return (
        <React.Fragment>
            <div className='relative justify-center items-center' style={{
                backgroundImage: 'url(/topbg.jpg)', backgroundSize: 'cover',
                backgroundPosition: 'center', height: 'calc(100vh - 8rem)'
            }}>
                <div className='absolute inset-0 bg-green-500 opacity-50 z-10'></div>
                <div className='absolute inset-0 flex flex-col justify-center items-center z-20 text-white'>
                    <h1 className='text-center font-medium pt-7 pb-10' style={{fontSize: '5.5vw'}}>Patsa - AI copilot
                        for meetings</h1>
                    <p className='text-center w-9/12 pb-10' style={{fontSize: '2.5vw'}}>Our extension listens to your
                        Zoom, Google Meet
                        and Teams call in real-time
                        and answers any questions instantly.</p>
                    <a href='https://chromewebstore.google.com/detail/patsa/pnhmmhieodnagejlokknhmhbmfpgohnn'
                       target='_blank' rel='noopener noreferrer'
                       className='h-14 mb-10 border-black border-2 bg-green-300 hover:bg-green-400 text-lg text-white font-bold py-2 px-16 rounded-full flex items-center justify-center'>
                        Let's Go!
                    </a>
                    <button onClick={handleClick}>
                        <img src='/arrow.gif' alt='Arrow' className='w-9' style={{width: '30px', height: '28px'}}/>
                    </button>
                </div>
            </div>
            <h1 ref={nextPageRef} className='w-full text-center text-5xl font-medium text-green-600 mt-6 mb-6'>
                How It Works
            </h1>
            <div className='text-center bg-gray-200 px-10 w-full'>
                <div className='grid grid-cols-2 bg-white  justify-center items-center'>
                    <div><img src='/1.jpg' alt='1'/></div>
                    <div className='flex flex-col justify-center items-center px-2.5'>
                        <p className='text-center text-3xl w-9/12'>Install Chrome extension</p>
                        <p className='text-center text-gray-800 font-light pt-5 w-9/12'>
                            Use our Chrome extension Patsa for Google Meet, Zoom, or Microsoft Teams. It captures audio
                            directly from your browser, so it's important to conduct your video conference within the
                            browser.
                        </p>
                    </div>
                    <div className='flex flex-col justify-center items-center px-2.5'>
                        <p className='text-center text-3xl w-9/12'>Receive instant answers in real-time</p>
                        <p className='text-center text-gray-800 font-light pt-5 w-9/12'>
                            During the video call, simply select transcribed text, and our extension will deliver
                            answers.
                        </p>
                    </div>
                    <div><img src='/2.jpg' alt='2'/></div>
                    <div><img src='/3.jpg' alt='3'/></div>
                    <div className='flex flex-col justify-center items-center px-2.5'>
                        <p className='text-center text-3xl w-9/12'>Power of AI</p>
                        <p className='text-center text-gray-800 font-light pt-5 w-9/12'>
                            Our extension is powered by AI, providing you with accurate answers to questions on
                            programming, analytics, math and others. Save time by not having to type out answers or
                            research information.
                        </p>
                    </div>
                </div>
                <h1 className='w-full text-center text-4xl font-bold mt-14 mb-10'>
                    Why Choose Us?
                </h1>
                <div className='flex flex-wrap pb-20'>
                    <div className='w-full sm:w-1/3'>
                        <div className='flex'>
                            <GoStar className='h-6 w-6 mr-2 text-gray-700'/>
                            <h1 className='text-lg text-left font-bold'>Be a know-it-all expert!</h1>
                        </div>
                        <p className='text-left text-gray-800 mt-3'>
                            Receive additional information and answers to colleagues' questions directly in real-time
                            during a meeting based on the context of the conversation.
                        </p>
                    </div>
                    <div className='w-full sm:w-1/3'>
                        <div className='flex'>
                            <IoLanguage className='h-6 w-6 mr-2 text-gray-700'/>
                            <h1 className='text-lg text-left font-bold'>Multilingual</h1>
                        </div>
                        <p className='text-left text-gray-800 mt-3'>
                            Patsa supports English, Russian, Spanish, German, French, Italian, Portuguese, Chinese,
                            Japanese.
                        </p>
                    </div>
                    <div className='w-full sm:w-1/3'>
                        <div className='flex'>
                            <GoPeople className='h-6 w-6 mr-2 text-gray-700'/>
                            <h1 className='text-lg text-left font-bold'>Convenience</h1>
                        </div>
                        <p className='text-left text-gray-800 mt-3'>
                            You receive information in one click that your meeting colleagues do not see.
                        </p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Landing;