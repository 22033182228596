import React from "react";
import { Link } from 'react-router-dom';

const Terms  = () => {
    return (
        <section className='mx-auto max-w-screen-lg px-4 py-16 md:px-0'>
            <h1 className='mb-4 text-2xl font-bold text-black'>Terms and Conditions of Use for Patsa</h1>
            <p className='text-left text-gray3 mb-12 font-light'>
                These Terms and Conditions of Use ("Terms") govern your use of the Patsa product ("Product") developed
                and provided by Patsa. By accessing or using the Product, you agree to be bound by these Terms. If you
                do not agree to these Terms, you should not use the Product.
            </p>
            <h2 className='mb-4 text-lg font-bold text-black'>Acceptance of Terms</h2>
            <p className='text-left text-gray3 mb-12 font-light'>
                By accessing or using the Product, you represent and warrant that you are of legal age to enter into a
                binding agreement and have read, understood, and agree to be bound by these Terms. If you are using the
                Product on behalf of an organization, you represent and warrant that you have the authority to bind that
                organization to these Terms, and "you" and "your" will refer to that organization.
            </p>
            <h2 className='mb-4 text-lg font-bold text-black'>License and Intellectual Property</h2>
            <p className='text-left text-gray3 font-light mb-12'>
                Patsa grants you a limited, non-exclusive, non-transferable, and revocable license to use the Product,
                subject to these Terms. All intellectual property rights in the Product, including but not limited to
                trademarks, copyrights, patents, and trade secrets, are and will remain the exclusive property of Patsa.
            </p>
            <h2 className='mb-4 text-lg font-bold text-black'>User Obligations</h2>
            <ul className='list-disc pl-6 mb-12'>
                <li className='text-left text-gray3 font-light'>
                    You agree to use the Product only for lawful purposes and in accordance with these Terms.
                </li>
                <li className='text-left text-gray3 font-light'>
                    You are responsible for maintaining the confidentiality of any account credentials or login
                    information associated with the Product.
                </li>
                <li className='text-left text-gray3 font-light'>
                    You agree not to use the Product to:
                    <ul className='pl-6'>
                        <li className='text-left text-gray3 font-light'>
                            <span className="mr-1">-</span> Violate any applicable law or regulation.
                        </li>
                        <li className='text-left text-gray3 font-light'>
                            <span className="mr-1">-</span> Infringe upon the rights of others.
                        </li>
                        <li className='text-left text-gray3 font-light'>
                            <span className="mr-1">-</span> Transmit any viruses, malware, or harmful code.
                        </li>
                        <li className='text-left text-gray3 font-light'>
                            <span className="mr-1">-</span> Interfere with or disrupt the Product or its associated
                            networks and systems.
                        </li>
                        <li className='text-left text-gray3 font-light'>
                            <span className="mr-1">-</span> Attempt to gain unauthorized access to the Product or any
                            user accounts.
                        </li>
                    </ul>
                </li>
            </ul>
            <h2 className='mb-4 text-lg font-bold text-black'>Privacy</h2>
            <p className='text-left text-gray3 font-light mb-12'>
                Your privacy is important to us. Our <Link to='/policy' className='text-blue-500 hover:underline'>Privacy
                Policy</Link> describes the information we collect, how we use it, and your rights regarding your
                personal data. By using the Product, you consent to the collection and use of your information as
                described in the <Link to='/policy' className='text-blue-500 hover:underline'>Privacy Policy</Link>.
            </p>
            <h2 className='mb-4 text-lg font-bold text-black'>Disclaimer of Warranties</h2>
            <ul className='list-disc pl-6 mb-12'>
                <li className='text-left text-gray3 font-light'>
                    The Product is provided on an "as is" and "as available" basis. Patsa makes no warranties, express
                    or implied, regarding the Product, including but not limited to its accuracy, reliability,
                    suitability, or availability.
                </li>
                <li className='text-left text-gray3 font-light'>
                    Patsa does not warrant that the Product will be uninterrupted, error-free, or secure, or that any
                    defects will be corrected.
                </li>
                <li className='text-left text-gray3 font-light'>
                    Your use of the Product is at your own risk, and you are solely responsible for any damage or loss
                    resulting from such use.
                </li>
            </ul>
            <h2 className='mb-4 text-lg font-bold text-black'>Limitation of Liability</h2>
            <ul className='list-disc pl-6 mb-12'>
                <li className='text-left text-gray3 font-light'>
                    To the maximum extent permitted by law, Patsa and its affiliates, officers, directors, employees,
                    agents, and suppliers shall not be liable for any direct, indirect, incidental, special,
                    consequential, or exemplary damages, including but not limited to damages for loss of profits,
                    goodwill, data, or other intangible losses, resulting from your use of the Product.
                </li>
                <li className='text-left text-gray3 font-light'>
                    Patsa's total liability, whether in contract, tort (including negligence), or otherwise, shall not
                    exceed the amount paid by you, if any, for accessing and using the Product during the six (6) months
                    preceding the claim.
                </li>
            </ul>
            <h2 className='mb-4 text-lg font-bold text-black'>Modifications and Termination</h2>
            <ul className='list-disc pl-6 mb-12'>
                <li className='text-left text-gray3 font-light'>
                    Patsa reserves the right to modify or discontinue the Product, or any portion thereof, at any time
                    without prior notice.
                </li>
                <li className='text-left text-gray3 font-light'>
                    Patsa may also modify these Terms from time to time. Any changes will be effective immediately upon
                    posting the revised Terms on the Product's website.
                </li>
                <li className='text-left text-gray3 font-light'>
                    If you violate these Terms, Patsa may terminate your access to the Product, without prejudice to any
                    other rights or remedies available to us.
                </li>
            </ul>
            <h2 className='mb-4 text-lg font-bold text-black'>Governing Law and Jurisdiction</h2>
            <p className='text-left text-gray3 font-light mb-12'>
                These Terms shall be governed by and construed in accordance with the laws of the State of Delaware,
                United States, without regard to its conflict of laws principles. Any legal action or proceeding arising
                out of or related to these Terms shall be brought exclusively in the state or federal courts located in
                Delaware, and you consent to the personal jurisdiction of such courts.
            </p>
            <h2 className='mb-4 text-lg font-bold text-black'>Severability</h2>
            <p className='text-left text-gray3 font-light mb-12'>
                If any provision of these Terms is found to be invalid, illegal, or unenforceable, the remaining
                provisions shall continue in full force and effect.
            </p>
            <h2 className='mb-4 text-lg font-bold text-black'>Entire Agreement</h2>
            <p className='text-left text-gray3 font-light mb-12'>
                These Terms constitute the entire agreement between you and Patsa regarding the use of the Product and
                supersede any prior or contemporaneous agreements, communications, or understandings, whether oral or
                written.
            </p>
            <h2 className='mb-4 text-lg font-bold text-black'>Contact Us</h2>
            <p className='text-left text-gray3 font-light mb-12'>
                If you have any questions about this Privacy Policy, you can contact us at <a
                className='text-blue-500 hover:underline'
                href='mailto:aicopilot@patsa.io'>aicopilot@patsa.io</a>.
            </p>
        </section>
    );
}

export default Terms;