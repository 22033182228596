import React, {useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import Landing from './Landing';
import UserDetails from './UserDetails';
import Policy from './Policy';
import Terms from './Terms';
import NotFound from './NotFound';
import PaymentSuccess from './PaymentSuccess';
import ScrollToTop from "./ScrollToTop";

const App = () => {
    const [startPage, setStartPage] = useState(null);

    useEffect(() => {
        const jwt = localStorage.getItem('jwt');

        if (jwt) {
            setStartPage(<UserDetails/>);
        } else {
            setStartPage(<Landing/>);
        }
    }, []);

    return (
        <BrowserRouter>
            <ScrollToTop/>
            <div className="flex flex-col min-h-screen">
                <Header/>
                <main className="flex-1">
                    <Routes>
                        <Route path='*' element={<NotFound/>}/>
                        <Route path='/' element={startPage}/>
                        <Route path='/user' element={<UserDetails/>}/>
                        <Route path='/landing' element={<Landing/>}/>
                        <Route path='/policy' element={<Policy/>}/>
                        <Route path='/terms' element={<Terms/>}/>
                        <Route path='/success' element={<PaymentSuccess/>}/>
                    </Routes>
                </main>
                <Footer/>
            </div>
        </BrowserRouter>
    );
};

export default App;


