import React, {useState} from 'react';
import config from '../config';

const ForgotPassword = ({onClose}) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [email, setEmail] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const enteredEmail = event.target.elements.email.value;

        try {
            const response = await fetch(`${config.httpUrl}/forgotpassword`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: `email=${encodeURIComponent(enteredEmail)}`,
            });

            if (response.ok) {
                setEmail(enteredEmail);
            } else {
                const responseBody = await response.text();
                setErrorMessage(responseBody ? responseBody : 'Error sending new password');
            }
        } catch (error) {
            console.error('An error occurred during password reset:', error);
            setErrorMessage('An error occurred during password reset.');
        }
    };

    return (
        <React.Fragment>
            <div
                className='fixed inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm flex items-center justify-center z-30'/>
            <div className='fixed inset-0 flex items-center justify-center z-30'>
                <div className='bg-white p-6 max-w-md rounded-lg shadow-md border-green-600 border-2'>
                    {email && (
                        <React.Fragment>
                            <p className='mt-2 text-center text-sm text-gray-600'>
                                Your new password has been sent to <b>{email}</b>. Please Login.
                            </p>
                            <div className='mt-4 flex justify-center items-center'>
                                <button type='button' onClick={onClose}
                                        className='py-2 px-4 justify-center border border-transparent text-sm font-medium rounded-lg text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'>
                                    Close
                                </button>
                            </div>
                        </React.Fragment>
                    )}
                    {
                        !email && (
                            <form className='space-y-6' onSubmit={handleSubmit}>
                                <div>
                                    <label htmlFor='email' className='block text-sm font-medium text-gray-700'>
                                        Email
                                    </label>
                                    <div className='mt-1'>
                                        <input id='email' name='email' type='email' required
                                               className='appearance-none rounded-lg relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 text-sm'
                                               placeholder='Enter your email address'/>
                                    </div>
                                </div>
                                {errorMessage && <p className='mt-2 text-center text-sm text-red-600'>{errorMessage}</p>}
                                <div className='grid grid-cols-2 gap-2'>
                                    <div>
                                        <button type='submit'
                                                className='w-full py-2 justify-center border border-transparent text-sm font-medium rounded-lg text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'>
                                            Reset
                                        </button>
                                    </div>
                                    <div>
                                        <button type='button' onClick={onClose}
                                                className='w-full py-2 justify-center border border-transparent text-sm font-medium rounded-lg text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'>
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </form>
                        )
                    }
                </div>
            </div>
        </React.Fragment>
    );
};

export default ForgotPassword;
