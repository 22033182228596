import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import config from '../config';
import GoogleSignIn from './GoogleSignIn';
import {GoogleOAuthProvider} from "@react-oauth/google";

const SignUp = ({onClose}) => {
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const email = event.target.elements.email.value;
        const password = event.target.elements.password.value;
        const confirmPassword = event.target.elements.confirmPassword.value;
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};:'"\\|,.<>/?]).{8,}$/;

        if (!passwordRegex.test(password)) {
            event.target.elements.password.setCustomValidity('Password must be at least 8 characters, include at least one uppercase/overcase letters, one number, and one special character.');
            return;
        }

        if (password !== confirmPassword) {
            event.target.elements.confirmPassword.setCustomValidity('Passwords do not match.');
            return;
        }

        try {
            const response = await fetch(`${config.httpUrl}/signup`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: `email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`,
            });

            const responseBody = await response.text();

            if (response.ok) {
                localStorage.setItem('jwt', responseBody);
                navigate('/user');
                onClose();
            } else {
                setErrorMessage(responseBody ? responseBody : 'Sign Up failed.');
            }
        } catch (error) {
            console.error('An error occurred during signup:', error);
            setErrorMessage('An error occurred during sign up.');
        }
    };

    return (
        <React.Fragment>
            <div
                className='fixed inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm flex items-center justify-center z-30'/>
            <div className='fixed inset-0 flex items-center justify-center z-30'>
                <div className='bg-white p-6 max-w-md rounded-lg shadow-md border-green-600 border-2'>
                    <form className='space-y-6' onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor='email' className='block text-sm font-medium text-gray-700'>
                                Email
                            </label>
                            <div className='mt-1'>
                                <input id='email' name='email' type='email' required
                                       className='appearance-none rounded-lg relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 text-sm'
                                       placeholder='Enter your email address'/>
                            </div>
                        </div>
                        <div>
                            <label htmlFor='password' className='block text-sm font-medium text-gray-700'>
                                Password
                            </label>
                            <div className='mt-1'>
                                <input id='password' name='password' type='password' required
                                       onChange={event => event.target.setCustomValidity('')}
                                       className='appearance-none rounded-lg relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 text-sm'
                                       placeholder='Enter your password'/>
                            </div>
                        </div>
                        <div>
                            <label htmlFor='confirmPassword' className='block text-sm font-medium text-gray-700'>
                                Confirm password
                            </label>
                            <div className='mt-1'>
                                <input id='confirmPassword' name='confirmPassword' type='password' required
                                       onChange={event => event.target.setCustomValidity('')}
                                       className='appearance-none rounded-lg relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 text-sm'
                                       placeholder='Enter your confirm password'/>
                            </div>
                        </div>
                        {errorMessage && <p className='mt-2 text-center text-sm text-red-600 '>{errorMessage}</p>}
                        <div className='grid grid-cols-2 gap-2'>
                            <div>
                                <button type='submit'
                                        className='w-full py-2 justify-center border border-transparent text-sm font-medium rounded-lg text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'>
                                    Sign Up
                                </button>
                            </div>
                            <div>
                                <button type='button' onClick={onClose}
                                        className='w-full py-2 justify-center border border-transparent text-sm font-medium rounded-lg text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </form>
                    <GoogleOAuthProvider clientId={config.clientId}>
                        <GoogleSignIn onClose={onClose}/>
                    </GoogleOAuthProvider>
                </div>
            </div>
        </React.Fragment>
    );
};

export default SignUp;
