import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import config from './config';

const PaymentSuccess = () => {
    const [message, setMessage] = useState('');
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get('session_id');

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!sessionId) {
                    setMessage('Session ID not found in the URL');
                    return;
                }

                const response = await fetch(`${config.httpUrl}/checkout/success`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: `sessionId=${encodeURIComponent(sessionId)}`,
                });

                if (response.ok) {
                    const data = await response.text();

                    if ('Approved' === data) {
                        setMessage('The balance was updated successfully');
                    } else {
                        setMessage('Payment was rejected');
                    }
                } else {
                    setMessage('Failed to complete balance update');
                }
            } catch (error) {
                console.error('Error:', error);
                setMessage('Failed to complete balance update');
            }
        };

        fetchData();
    }, [sessionId]);

    return (
        <div className='flex items-center justify-center'>
            <div className='text-center'>
                <p>{message}</p>
                <Link to={localStorage.getItem('jwt') ? '/user' : '/landing'} className='text-green-600'>
                    Back
                </Link>
            </div>
        </div>
    );
};

export default PaymentSuccess;
