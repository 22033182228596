import React from 'react';

const Policy = () => {
    return (
        <section className='mx-auto max-w-screen-lg px-4 py-16 md:px-0'>
            <h1 className='mb-4 text-2xl font-bold text-black'>Privacy policy</h1>
            <p className='text-left text-gray3 mb-12 font-light'>
                The following Privacy Policy governs the online information collection practices of Patsa (“Company,”
                'we' or 'us'). It outlines the types of information that we gather about you while you are using using
                our website <a className='text-blue-500 hover:underline' href={'https://patsa.io' + localStorage.getItem('jwt') ? '/user' : ''}>https://patsa.io</a> or our
                chrome extension (the 'Sites') and the ways in which we use this
                information. We process your data in accordance with the applicable laws and regulations following
                industry best practices.</p>
            <h2 className='mb-4 text-lg font-bold text-black'>Purpose and scope</h2>
            <p className='text-left text-gray3 mb-12 font-light'>
                The Privacy Policy applies primarily to information that we collect online; however, it may apply to
                some of the data that you provide to us offline and/or through other means, as well (for example, via
                telephone, or through the mail). The Privacy Policy documents and describes the manner in which our
                organisation collects information from all our Users of our Services. The use of information collected
                on behalf of our Users is governed primarily by our contract for the engagement with them and the
                privacy policies governing their organisation. We are not accountable for the privacy policies and
                practices undertaken by our users or any other third parties Some information is collected through the
                use of cookies, web beacons and tracking technologies. Please refer to the cookie policy for more
                details on the type of cookies being used.
            </p>
            <h2 className='mb-4 text-lg font-bold text-black'>How we collect data about you</h2>
            <p className='text-left text-gray3 font-light'>
                We may collect different data from or about you depending on how
                you use the Sites.
            </p>
            <ul className='list-disc pl-6'>
                <li className='text-left text-gray3 font-light'>
                    Data provided by registered users: Account creation and login
                    - When you create an account or login into your account, we collect personal information
                    such as email address and password.
                </li>
                <li className='text-left text-gray3 font-light mb-12'>
                    Data collected during transactions: Transactional Data - On
                    the basis of types of services requested, Patsa also collects information such as: -
                    type of service that has been requested - order details for such a request - payment
                    &amp; transaction information (such as merchant's name, payment ,method, chargeable
                    amount for the service) - date and time the service provided
                </li>
            </ul>
            <h2 className='mb-4 text-lg font-bold text-black'>The Types of Information We Collect and Store</h2>
            <p className='text-left text-gray3 font-light'>
                We may collect personal and sensitive personal information about you and store this information in
                connection
                with the provision and fulfilment of our services to you. Personal information may include:
            </p>
            <ul className='list-disc pl-6'>
                <li className='text-left text-gray3 font-light mb-12'>Email address</li>
            </ul>
            <h2 className='mb-4 text-lg font-bold text-black'>How We Use Your Personal Information</h2>
            <p className='text-left text-gray3 font-light'>
                The above personal information may be used for the following purposes:
            </p>
            <ul className='list-disc pl-6'>
                <li className='text-left text-gray3 font-light'>To provide you with information about our offerings
                    and/or our periodic newsletters
                </li>
                <li className='text-left text-gray3 font-light'>To respond to your requests</li>
                <li className='text-left text-gray3 font-light'>To manage our relationship with you</li>
                <li className='text-left text-gray3 font-light'>For internal record keeping</li>
                <li className='text-left text-gray3 font-light'>To provide you with authorisation to login and use your
                    user account
                </li>
                <li className='text-left text-gray3 font-light'>To verify your identity</li>
                <li className='text-left text-gray3 font-light'>To maintain correct and up-to-date information about
                    you
                </li>
                <li className='text-left text-gray3 font-light'>To identify you as a contracting party</li>
                <li className='text-left text-gray3 font-light'>To enable automated handling of the subscriptions</li>
                <li className='text-left text-gray3 font-light'>To otherwise be able to provide the services to you</li>
                <li className='text-left text-gray3 font-light mb-12'>To comply with our legal or statutory
                    obligations
                </li>
            </ul>
            <h2 className='mb-4 text-lg font-bold text-black'>Who has access to your data within our organisation?</h2>
            <p className='text-left text-gray3 font-light mb-12'>
                Within our organisation, access to your data is limited to those persons who require access in order to
                provide you with the Products and Services, which you purchase from us, to contact you, and to respond
                to your inquiries, including requests for refund. Those staff members may be on teams such as:
                marketing, events, development, executive, customer support. Employees only have access to data that is
                relevant to their team, on a ‘need to know’ basis.
            </p>
            <h2 className='mb-4 text-lg font-bold text-black'>
                Who do we share your data with outside our organisation and why?</h2>
            <h3 className='mb-3 font-bold text-black'>Processors</h3>
            <p className='text-left text-gray3 font-light mb-5'>
                We may use service providers & third parties for operating and improving the Sites, to assist with
                certain functions, such as payment processing, email transmission, conducting surveys or contests, data
                hosting, third-party solutions for marketing and analytics, and some aspects of our technical and
                customer support. We take measures to ensure that these service providers access, process, and store
                information about you only for the purposes we authorise, through the execution of Data Processing
                Agreements or Addenda.
            </p>
            <h3 className='mb-3 font-bold text-black'>Authorities</h3>
            <p className='text-left text-gray3 font-light mb-5'>
                We may access, preserve, and disclose information about you to third parties, including the content of
                messages if we believe disclosure is in accordance with or required by, applicable law, regulation,
                legal process, or audits. We may also disclose information about you if we believe that your actions are
                inconsistent with our Terms of Service or related guidelines and policies, or if necessary to protect
                the rights, property, or safety of, or prevent fraud or abuse of, Company or others.
            </p>
            <h3 className='mb-3 font-bold text-black'>Transfer of business</h3>
            <p className='text-left text-gray3 font-light mb-12'>
                If we (or our assets) are acquired, or if we go out of business, enter bankruptcy, or go through some
                other change of control, personal information could be one of the assets transferred to or acquired by a
                third party
            </p>
            <h2 className='mb-4 text-lg font-bold text-black'>Why and for how long do we store information we collect
                from you?</h2>
            <p className='text-left text-gray3 font-light'>
                We retain certain information collected from you while you are a member on the Sites, and in certain
                cases where you have deleted your account, for the following reasons:
            </p>
            <ul className='list-disc pl-6'>
                <li className='text-left text-gray3 font-light'>So that you can use our Sites</li>
                <li className='text-left text-gray3 font-light'>
                    To ensure that we do not communicate with you if you have asked us not to
                </li>
                <li className='text-left text-gray3 font-light'>To provide you with a refund, if entitled</li>
                <li className='text-left text-gray3 font-light'>
                    To better understand the traffic to our Sites so that we can provide all members with the best
                    possible experience
                </li>
                <li className='text-left text-gray3 font-light'>
                    To detect and prevent abuse of our Sites, illegal activities and breaches of our Terms of
                    Service
                </li>
                <li className='text-left text-gray3 font-light'>To comply with applicable legal, tax or accounting
                    requirements
                </li>
            </ul>
            <p className='text-left text-gray3 font-light mb-12'>
                We retain user data for as long as necessary for the purposes described above. This means that we retain
                different categories of data for different periods of time depending on the type of data, the category
                of user to whom the data relates to, and the purposes for which we collected the data.
            </p>
            <h2 className='mb-4 text-lg font-bold text-black'>Security</h2>
            <p className='text-left text-gray3 font-light mb-12'>
                We implement a variety of administrative, managerial, and technical security measures to help protect
                your personal information. Our Company has various internal control standards which relate specifically
                to the handling of personal information. These include certain controls to help safeguard the
                information we collect online. Our employees are trained to understand and comply with these controls
                and we communicate our Privacy Policy practices and guidelines to our employees. However, while we
                strive to protect your personal information, you must also take steps to protect your information. We
                urge you to take every precaution to protect your personal information while you are on the Internet.
            </p>
            <h2 className='mb-4 text-lg font-bold text-black'>Your rights under the GDPR</h2>
            <p className='text-left text-gray3 font-light'>
                You have certain rights if you are within the EU this includes:
            </p>
            <ul className='list-disc pl-6'>
                <li className='text-left text-gray3 font-light'>
                    Right to access. This right allows you to obtain a copy of your personal data, as well as other
                    supplementary information
                </li>
                <li className='text-left text-gray3 font-light'>
                    Right to restrict processing. You have the right to
                    restrict the processing of your personal
                    data in
                    certain circumstances
                </li>
                <li className='text-left text-gray3 font-light'>
                    Right to rectification. You have the right to have any incomplete or inaccurate information we
                    hold
                    about you corrected
                </li>
                <li className='text-left text-gray3 font-light'>
                    Right to object to processing. The right to object
                    allows you to stop or prevent us from
                    processing your
                    personal data. This right exists where we are relying on a legitimate interest as the legal
                    basis for
                    processing your Personal Data. You also have the right to object where we are processing your
                    Personal
                    data for direct marketing purposes
                </li>
                <li className='text-left text-gray3 font-light'>
                    Right to erasure. You have the right to ask us to delete
                    or remove Personal data when the
                    personal data
                    is no longer necessary for the purpose which you originally collected or processed
                </li>
            </ul>
            <p className='text-left text-gray3 font-light mb-12'>
                To exercise your rights, you can contact our DPO with your request at the email <a
                className='text-blue-500 hover:underline' href='mailto:aicopilot@patsa.io'>aicopilot@patsa.io</a>
            </p>
            <h2 className='mb-4 text-lg font-bold text-black'>Our Policy Concerning Children &amp; Their Data</h2>
            <p className='text-left text-gray3 font-light mb-12'>
                Our Sites are not directed to children under the age of sixteen and we do not knowingly collect
                personally identifiable information from children or distribute such information to third parties. We
                screen users who wish to provide personal information in order to prevent children from providing such
                information. If we become aware that we have inadvertently received personally identifiable information
                from a child, we will delete such information from our records. If we change our practices in the
                future, we will obtain prior, verifiable parental consent before collecting any personally identifiable
                information from children.
            </p>
            <h2 className='mb-4 text-lg font-bold text-black'>Links to Other Websites</h2>
            <p className='text-left text-gray3 font-light mb-12'>
                Our Sites may contain links to other websites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.<br/><br/>
                Third parties are under no obligation to comply with this Privacy Policy with respect to Personal Data that you provide directly to those third parties or that those third parties collect for themselves. We do not control the third-party sites that may be accessible through our Services. Thus, this Privacy Policy does not apply to information you provide to third-party sites or gathered by the third parties that operate them.
            </p>
            <h2 className='mb-4 text-lg font-bold text-black'>Changes to This Policy</h2>
            <p className='text-left text-gray3 font-light mb-12'>
                We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. If we make any material changes to the Policy, we will notify you via email, through a notification posted on the Services, or as required by applicable law. You can see when the Policy was last updated by checking the date at the bottom of this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective from when they are posted on this page.
            </p>
            <h2 className='mb-4 text-lg font-bold text-black'>Contact Us</h2>
            <p className='text-left text-gray3 font-light mb-12'>
                If you have any questions about this Privacy Policy, you can contact us at <a className='text-blue-500 hover:underline' href='mailto:aicopilot@patsa.io'>aicopilot@patsa.io</a>.
            </p>
        </section>
    );
}

export default Policy;