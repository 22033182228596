import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import config from './config';

const UserDetails = () => {
    const [userInfo, setUserInfo] = useState(null);
    const navigate = useNavigate();
    const [showCheckoutForm, setShowCheckoutForm] = useState(false);
    const [showChangePasswordForm, setShowChangePasswordForm] = useState(false);
    const [errorMessageCheckout, setErrorMessageCheckout] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const jwt = localStorage.getItem('jwt');

                if (!jwt) {
                    navigate('/landing');
                }

                const response = await fetch(`${config.httpUrl}/users/current`, {
                    method: 'GET',
                    headers: {Authorization: `Bearer ${jwt}`}
                });

                if (response.ok) {
                    const userInfo = await response.json();
                    setUserInfo(userInfo);
                } else {
                    const responseBody = await response.text();
                    console.log('Get user failed: ', responseBody);

                    navigate('/landing');
                }
            } catch (error) {
                console.error('Error getting user details: ', error);
                navigate('/landing');
            }
        };

        fetchUserInfo();
    }, [navigate]);

    const handleCheckout = async (event) => {
        event.preventDefault();
        const amount = event.target.elements.amount.value;

        try {
            const jwt = localStorage.getItem('jwt');

            const response = await fetch(`${config.httpUrl}/checkout`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${jwt}`
                },
                body: JSON.stringify({
                    quantity: amount,
                    success: config.paymentUrl,
                    cancel: config.patsaUrl
                }),
            });

            const responseBody = await response.text();

            if (response.ok) {
                console.debug('Done');
                window.location.href = responseBody;
            } else {
                setErrorMessageCheckout(responseBody);
            }
        } catch (error) {
            console.error('Error:', error);
            setErrorMessageCheckout('Failed to checkout');
        }
    };

    const handleChangePassword = async (event) => {
        event.preventDefault();
        const oldPassword = event.target.elements.oldPassword.value;
        const password = event.target.elements.password.value;
        const confirmPassword = event.target.elements.confirmPassword.value;
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};:'"\\|,.<>/?]).{8,}$/;

        if (!passwordRegex.test(password)) {
            event.target.elements.password.setCustomValidity('Password must be at least 8 characters, include at least one uppercase/overcase letters, one number, and one special character.');
            return;
        }

        if (oldPassword === password) {
            event.target.elements.password.setCustomValidity('New password should be different to old one.');
            return;
        }

        if (password !== confirmPassword) {
            event.target.elements.confirmPassword.setCustomValidity('Passwords do not match.');
            return;
        }

        try {
            const jwt = localStorage.getItem('jwt');

            const response = await fetch(`${config.httpUrl}/changepassword`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: `Bearer ${jwt}`
                },
                body: `oldPassword=${encodeURIComponent(oldPassword)}&password=${encodeURIComponent(password)}`,
            });

            const responseBody = await response.text();

            if (response.ok) {
                localStorage.setItem('jwt', responseBody);
                setShowChangePasswordForm(false);
            } else {
                setErrorMessage(responseBody ? responseBody : 'Change password failed.');
            }
        } catch (error) {
            console.error('An error occurred while changing password:', error);
            setErrorMessage('An error occurred while changing password.');
        }
    }

    if (!userInfo) {
        return (
            <div className='flex w-full justify-center'>
                <svg className='animate-spin h-4 w-4 text-green-400 mr-3 mt-2' xmlns='http://www.w3.org/2000/svg'
                     fill='none'
                     viewBox='0 0 24 24'>
                    <circle className='opacity-25' cx='12' cy='12' r='10' stroke='currentColor'></circle>
                    <path className='opacity-75' fill='currentColor'
                          d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'>
                    </path>
                </svg>
                <span className='mt-1 text-base text-center font-bold text-gray-900'>Loading...</span>
            </div>
        );
    }

    return (
        <div className='flex flex-col justify-center px-10 py-5'>
            <div className='mx-auto w-full'>
                <h2 className='mt-3 text-center text-2xl font-bold text-gray-900'>
                    Welcome, {userInfo.email}
                </h2>
            </div>
            <div>
                <p className='text-sm mt-2 text-left font-bold text-gray-700'>
                    Your balance:
                </p>
                <p className='text-sm mt-2 text-left text-gray-700'>
                    {
                        userInfo.remainingTime < 60
                            ?
                            <React.Fragment><b>{userInfo.remainingTime}</b> second{userInfo.remainingTime === 1 ? '' : 's'}
                            </React.Fragment>
                            : userInfo.remainingTime % 60 === 0
                                ?
                                <React.Fragment><b>{userInfo.remainingTime / 60}</b> minute{userInfo.remainingTime / 60 === 1 ? '' : 's'}
                                </React.Fragment>
                                :
                                <React.Fragment><b>{Math.floor(userInfo.remainingTime / 60)}</b> minute{Math.floor(userInfo.remainingTime / 60) === 1 ? '' : 's'}
                                    <b> {userInfo.remainingTime % 60}</b> second{userInfo.remainingTime % 60 === 1 ? '' : 's'}
                                </React.Fragment>
                    } of transcription left
                </p>
            </div>
            <div className='mt-6'>
                {showCheckoutForm ? (
                    <form onSubmit={handleCheckout} className='flex space-x-2'>
                        <label htmlFor='hoursInput' className='text-sm mt-2 text-left text-gray-700'>
                            How many hours do you want to buy (<b>1 hour - 1.89$</b>)?
                        </label>
                        <input type='number' id='amount' name='amount' defaultValue='1' required
                            className='w-24 rounded-lg relative block px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 text-sm'/>
                        <button type='submit'
                                className='p-2 justify-center border border-transparent text-sm font-medium rounded-lg text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'>
                            Checkout
                        </button>
                        {errorMessageCheckout && <p className='mt-2 text-sm text-red-600'>{errorMessageCheckout}</p>}
                    </form>
                ) : (
                    <button type='button' onClick={() => {
                        setShowCheckoutForm(true)
                    }}
                            className='p-2 justify-center border border-transparent text-sm font-medium rounded-lg text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'>
                        Buy hours
                    </button>
                )
                }
            </div>
            {!userInfo.externalSignUp && (
                <div className='mt-6'>
                    {showChangePasswordForm ? (
                        <form className='space-y-6' onSubmit={handleChangePassword}>
                            <div>
                                <label htmlFor='oldPassword' className='block text-sm font-medium text-gray-700'>
                                    Old password
                                </label>
                                <div className='mt-1'>
                                    <input id='oldPassword' name='oldPassword' type='password' required
                                           className='appearance-none rounded-lg relative block px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 text-sm'
                                           placeholder='Enter your password'/>
                                </div>
                            </div>
                            <div>
                                <label htmlFor='password' className='block text-sm font-medium text-gray-700'>
                                    New password
                                </label>
                                <div className='mt-1'>
                                    <input id='password' name='password' type='password' required
                                           onChange={e => e.target.setCustomValidity('')}
                                           className='appearance-none rounded-lg relative block px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 text-sm'
                                           placeholder='Enter your new password'/>
                                </div>
                            </div>
                            <div>
                                <label htmlFor='confirmPassword'
                                       className='block text-sm font-medium text-gray-700'>
                                    Confirm new password
                                </label>
                                <div className='mt-1'>
                                    <input id='confirmPassword' name='confirmPassword' type='password' required
                                           onChange={e => e.target.setCustomValidity('')}
                                           className='appearance-none rounded-lg relative block px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 text-sm'
                                           placeholder='Enter your confirm password'/>
                                </div>
                            </div>
                            {errorMessage &&
                                <p className='mt-2 text-center text-sm text-red-600 '>{errorMessage}</p>}
                            <div className='flex'>
                                <div>
                                    <button type='submit'
                                            className='mr-6 p-2 justify-center border border-transparent text-sm font-medium rounded-lg text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'>
                                        Change
                                    </button>
                                </div>
                                <div>
                                    <button type='button' onClick={() => setShowChangePasswordForm(false)}
                                            className='p-2 justify-center border border-transparent text-sm font-medium rounded-lg text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    ) : (
                        <button type='button' onClick={() => setShowChangePasswordForm(true)}
                                className='p-2 justify-center border border-transparent text-sm font-medium rounded-lg text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'>
                            Change password
                        </button>
                    )
                    }
                </div>)
            }
        </div>
    );
}

export default UserDetails;
