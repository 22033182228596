const config = {
    //httpUrl: 'http://localhost:8080',
    //paymentUrl: 'http://localhost:3000/success?session_id={CHECKOUT_SESSION_ID}',
    //patsaUrl: 'http://localhost:3000/user',
    httpUrl: 'https://patsa-kx6iklqsqa-ew.a.run.app',
    paymentUrl: 'https://patsa.io/success?session_id={CHECKOUT_SESSION_ID}',
    patsaUrl: 'https://patsa.io/user',
    clientId: '59020193239-tp2cqug3takk5de2g49pff47qr198qse.apps.googleusercontent.com'
};

export default config;