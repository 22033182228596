import React from "react";

const NotFound  = () => {
    return (
        <div className='text-center'>
            <p>OOPS!</p>
        </div>
    );
}

export default NotFound;